import {lazy} from 'react';
import * as PATH from "./Slug";
import ROLES from "../helpers/Roles";

const Chatbot = lazy(() => import("../pages/chatbot/Chatbot"));

const UatFiles = lazy(() => import("../pages/uat/UatFiles"));
const UatFilesHistory = lazy(() => import("../pages/uat/UatFilesHistory"));

const ProductionFiles = lazy(() => import("../pages/production/ProductionFiles"));

const UserListView = lazy(() => import("../pages/user/UserListView"));
const UserActivityLogList = lazy(() => import("../pages/user/UserActivityLogList"));
const UserProfile = lazy(() => import("../pages/user/UserProfile"));

const SettingsPage = lazy(() => import("../pages/settings/SettingsPage"));
const MonitoringPage = lazy(() => import("../pages/monitoring/MonitoringPage"));

const TelegramBotList = lazy(() => import("../pages/telegram/TelegramBotList"));

const Workflow = lazy(() => import("../pages/workflow/Workflow"));

const WebConfig = lazy(() => import("../pages/web_config/WebConfig"));

const PageRoutes = [
    {
        path: PATH.CHAT_BOT_PATH,
        component: Chatbot,
        roles: [ROLES.EP_SUPER_USER, ROLES.OPERATOR]
    },
    {
        path: PATH.UAT_PATH,
        component: UatFiles,
        roles: [ROLES.OPERATOR]
    },
    {
        path: `${PATH.UAT_HISTORY_PATH}/:id`,
        component: UatFilesHistory,
        roles: [ROLES.OPERATOR]
    },
    {
        path: PATH.PRODUCTION_PATH,
        component: ProductionFiles,
        roles: [ROLES.MANAGER]
    },
    {
        path: PATH.USER_LIST_VIEW_PATH,
        component: UserListView,
        roles: [ROLES.EP_SUPER_USER]
    },
    {
        path: `${PATH.USER_ACTIVITY_LOG_PATH}/:id`,
        component: UserActivityLogList,
        roles: [ROLES.EP_SUPER_USER]
    },
    {
        path: PATH.MY_PROFILE_PATH,
        component: UserProfile,
        roles: [ROLES.ALL]
    },
    {
        path: PATH.SETTINGS_PATH,
        component: SettingsPage,
        roles: [ROLES.EP_SUPER_USER]
    },
    {
        path: PATH.MONITORING_PATH,
        component: MonitoringPage,
        roles: [ROLES.ALL]
    },
    {
        path: PATH.TELEGRAM_BOT_LIST_PATH,
        component: TelegramBotList,
        roles: [ROLES.OPERATOR]
    },
    {
        path: PATH.WORKFLOW_PATH,
        component: Workflow,
        roles: [ROLES.OPERATOR]
    },
    {
        path: PATH.WEB_CONFIG_PATH,
        component: WebConfig,
        roles: [ROLES.OPERATOR]
    },
]

export default PageRoutes;
